import React, { useContext, useEffect, useReducer } from 'react';
import { FiltersContext } from './FiltersContext';
import { AxiosContext } from '../AxiosContext';
import { Context } from '../Context';
import { filtersReducer } from './filtersReducer';
import axios from 'axios';

const INITIAL_STATE = {
  isLoading: false,
  userOrchard_filter: [],
  userSpecies_filter: [],
  withDataOrchards: false,
  orchard_filter: null,
  orchard_id_filter: null,
  cc_filter: null,
  quarter_filter: null,
  variety_filter: null,
  rootstock_filter: null,
  idUnitProductive: null,
  season_filter: null,
  specie_filter: null,
};

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, INITIAL_STATE, () => {
    // const localState = localStorage.getItem('@session'); //se mantiene aunque cierre el navegador
    const localState = window.sessionStorage.getItem('@filters'); // se limpia cuando cierra nevagador
    return localState ? JSON.parse(localState) : INITIAL_STATE;
  });

  const { token, userOrchard, userClient } = useContext(Context);
  const axiosContext = useContext(AxiosContext);

  // useEffect(() => {
  // acción inicial
  // getUserLocation().then((lngLat) =>
  // 	dispatch({ type: 'setUserOrchards', payload: lngLat })
  // );
  // }, []);

  // const setUserOrchards = (payload) => {
  // 	dispatch({ type: 'set_user_orchard', payload: payload });
  // };

  const addFilter = (filter) => {
    dispatch({ type: 'add_filter', payload: filter });
  };

  // const getArrayofIds = (array) => {
  // 	console.log('getArrayofIds----------', array);
  // 	const newArray = array.map((item) => {
  // 		return item.orchard;
  // 	});
  // 	return newArray;
  // };

  const getMyOrchardsInfo = async (userOrch) => {
    // let objQuery = {
    // 	orchard: JSON.stringify(userOrchards),
    // };
    // if (token) {
    // const response = await axios.get(
    // 	'https://api.ceranalytics.staging.movelab.cl/v1/orchard',
    // 	{
    // 		params: userOrchards,
    // 		paramsSerializer: (params) => {
    // 			return qs.stringify(params);
    // 		},
    // 		headers: { Authorization: `Bearer ${token}` },
    // 	}
    // );

    // console.log('getMyOrchardsInfo------------', userOrch);

    // const orchardValuesArray = getArrayofIds(userOrch);

    // console.log(orchardValuesArray, '----orchardValuesArray');

    // console.log({
    //   params: {
    //     orchard: JSON.stringify(userOrch),
    //   },
    // });
    dispatch({ type: 'is_loading_orchards', payload: true });

    try {
      const response = await axiosContext.authAxios.get('v1/orchard', {
        params: {
          orchard: JSON.stringify(userOrch),
          clientValue: userClient?.value || null,
        },
        // paramsSerializer: (params) => {
        // 	return qs.stringify(params);
        // },
      });
      const { data, status } = response;

      console.log('----llegan los orchards---', data);

      const array_id = (array) => {
        const newArray = array.map((item) => {
          return {
            ...item,
            _id: item.id,
          };
        });
        return newArray;
      };

      // console.log('-------response---ORCHARD--------------->', response);
      dispatch({ type: 'set_user_orchard', payload: array_id(data) });
    } catch (error) {
      //   console.log(error, '--------error');
    } finally {
      dispatch({ type: 'is_loading_orchards', payload: false });
    }

    // }
  };

  const logOutFilters = () => {
    dispatch({ type: 'log_out', payload: INITIAL_STATE });
  };

  useEffect(() => {
    if (token && userOrchard && !state.withDataOrchards) {
      // solo lo ejecuta, cuando no hay estado en el navegador
      // console.log('ufe-------state----------', userOrchard, '---', state);
      console.log('se ejecuta get orchards');
      getMyOrchardsInfo(userOrchard);
    }
  }, [token, userOrchard]);

  useEffect(() => {
    // localStorage.setItem('@session', JSON.stringify(state));
    window.sessionStorage.setItem('@filters', JSON.stringify(state));
  }, [state]);

  return (
    <FiltersContext.Provider
      value={{
        state,
        // setUserOrchards,
        getMyOrchardsInfo,
        addFilter,
        logOutFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
