// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Button, IconButton, Modal, Tooltip, useTheme } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  IconActionTableDetail,
  IconActionTableEdit,
  ReactDataTable,
} from '../../../components';
// import { DATATABLE } from 'helpers/Mockups';
import { FiltersContext, OptionsContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { setOrderForApi, setOrderForNewApi } from '../../../helpers';
import ModalDetail from '../ModalDetail';
import { useErrorHandler } from 'react-error-boundary';
import {
  colsNoVisYieldCalculator,
  colsNoVisYieldCalculatorRaleo, // estas son las columas que se ocultan en columnas conteo frutos
  colsNoVisYieldCalculatorPrune,
  colsFixedYieldCalculator, // estas son columnas fija para la tabla
  colsYieldCalculatorWinterAndFruit, // estas son todas las columnas
  colsNoVisYieldCalculatorWinter, // estas son las columnas que se ocultan en columnas poda
} from '../../../helpers/tableDefinitions';
import ModalDetailPrint from '../ModalDetailPrint';
import ModalEdit from '../ModalEdit';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 27,
    // },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCol: {
    display: 'flex',
  },
  // backButton: {
  //   position: 'absolute',
  //   top: 10,
  //   zIndex: 100,
  // },
}));

const endPointName = 'v1/library/yield-calculator';

const FirstTab = ({
  filter: filterProp,
  fromPrune = false,
  scenarySaved = false,
}) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const history = useHistory();

  const { userOrchard, userClient } = useContext(Context);

  const {
    state: { specie_filter },
  } = useContext(FiltersContext);

  const {
    state: { library_table_yieldcalculator },
  } = useContext(OptionsContext);

  // console.log(scenarySaved, '<---scenarySaved');

  const axiosContext = useContext(AxiosContext);

  // modal
  const [openModalState, setOpenModalState] = useState(false);
  const [dataModalState, setDataModalState] = useState(null);

  // modal Print
  const [openModalPrintState, setOpenModalPrintState] = useState(false);
  const [dataModalPrintState, setDataModalPrintState] = useState(null);

  // modal Edit
  const [openModalEditState, setOpenModalEditState] = useState(false);
  const [dataModalEditState, setDataModalEditState] = useState(null);

  const customHeadStyles = {
    backgroundColor: theme.palette.primary.main,
    borderBottom: '4px solid',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  };

  // const colsYieldFilter =
  //   userClient?.id === '63ff5c7343f82fe93544ec64'
  //     ? colsYieldCalculator
  //     : colsYieldCalculator.filter((col) => col.accessorKey !== 'budDartReal');

  const columns = useMemo(
    () => colsYieldCalculatorWinterAndFruit(specie_filter),
    [specie_filter]
  );

  // const [visibleColumnsState, setVisibleColumnsState] = useState(
  //   colsNoVisYieldCalculatorWinter
  // );
  const [visibleColumnsState, setVisibleColumnsState] = useState( 
    colsNoVisYieldCalculatorRaleo, 
    
  );
  // const [activeColumnsTypeState, setActiveColumnsTypeState] = useState('poda');
  const [activeColumnsTypeState, setActiveColumnsTypeState] = useState('conteo');

  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(
    scenarySaved ? [{ id: 'season', desc: false }] : []
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [isEditedState, setIsEditedState] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const {
        huerto,
        centro_costo,
        cuartel,
        variedad,
        porta_injerto,
        temporada,
        specieValue,
      } = filterProp;

      let objQuery = {
        orchardValue: huerto,
        huerto_user: userOrchard, //orchard del login
        ccValue: centro_costo,
        quarterValue: cuartel,
        varietyValue: variedad,
        rootStockValue: porta_injerto,
        season: temporada,
        specieValue: specieValue,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        console.log(data, '----asi llega el first tab');

        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchData();
    }
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filterProp,
    isEditedState,
  ]);

  // modal
  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleCloseModalPrint = () => {
    setOpenModalPrintState(false);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEditState(false);
  };

  const handleOpenModal = (row) => {
    setDataModalState(row);
    setOpenModalState(true);
  };

  const handleOpenModalPrint = (row) => {
    setDataModalPrintState(row);
    setOpenModalPrintState(true);
  };

  const handleOpenModalEdit = (row) => {
    setDataModalEditState(row);
    setOpenModalEditState(true);
  };

  // console.log(new Date().getFullYear().toString(), '------actual year');
  const actualYear = new Date().getFullYear().toString();
  // TODO:
  // const actualYear = '2024';

  const actionsColumn = (row) => {
    return (
      <div className={clsx('actionColumn', classes.actionCol)}>
        <IconActionTableDetail
          handleActionDetail={() => {
            const html = row.original;
            handleOpenModal(html);
            console.info('estado modal: ', row);
          }}
        />
        {/* <IconActionTableEdit
          handleActionEdit={() => {
            const html = row.original;
            handleOpenModalEdit(html);
          }}
        /> */}
        {row.original.yieldObjective && row.original.season === actualYear ? (
          <Tooltip title='Imprimir Escenario'>
            <IconButton
              aria-label='imprimir'
              onClick={() => {
                const html = row.original;
                handleOpenModalPrint(html);
                console.info('estado modal: ', row);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const handleBack = () => {
    history.push({
      pathname: '/intelligence',
    });
  };

  const handleIsEdited = useCallback(() => {
    setIsEditedState((prev) => !prev);
  }, []);

  const handleColumnPresets = (type) => {
    switch (type) {
      case 'poda':
        setVisibleColumnsState(colsNoVisYieldCalculatorWinter);
        setActiveColumnsTypeState(type);
        break;
      case 'conteo':
        setVisibleColumnsState(colsNoVisYieldCalculatorRaleo);
        setActiveColumnsTypeState(type);
        break;
      default:
        setVisibleColumnsState(colsNoVisYieldCalculatorWinter);
        setActiveColumnsTypeState('poda');
        break;
    }
  };

  useEffect(() => {
    if (library_table_yieldcalculator && library_table_yieldcalculator.length) {
      console.log(library_table_yieldcalculator, '<---columns');
    }
  }, []);

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        {fromPrune ? (
          <Button onClick={handleBack} className={classes.backButton}>
            <i className='fas fa-chevron-left mr-2'></i> Volver Recomendación
            Poda
          </Button>
        ) : null}
        {!fromPrune ? (
          <>
            <Button
              sx={{
                backgroundColor:
                  activeColumnsTypeState === 'poda'
                    ? theme.palette.grey[100]
                    : '',
                color:
                  activeColumnsTypeState === 'poda'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[400],
              }}
              onClick={() => handleColumnPresets('poda')}
            >
              Columnas Poda
            </Button>
            <Button
              sx={{
                backgroundColor:
                  activeColumnsTypeState === 'conteo'
                    ? theme.palette.grey[100]
                    : '',
                color:
                  activeColumnsTypeState === 'conteo'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[400],
              }}
              onClick={() => handleColumnPresets('conteo')}
            >
              Columnas Conteo Frutos
            </Button>
          </>
        ) : null}
        <div className={classes.tableContainerStyle}>
          <ReactDataTable
            columns={columns}
            data={data}
            hideColumns={
              scenarySaved ? colsNoVisYieldCalculatorPrune : visibleColumnsState
            }
            fixedColumns={colsFixedYieldCalculator}
            // positionActionsColumn={'first'}
            isError={isError}
            isLoading={isLoading}
            isRefetching={isRefetching}
            module='Calculadora'
            onSortingChange={setSorting}
            sorting={sorting}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            onColumnFiltersChange={setColumnFilters}
            columnFilters={columnFilters}
            onGlobalFilterChange={setGlobalFilter}
            globalFilter={globalFilter}
            enableRowActions={true}
            renderRowActions={actionsColumn}
            headStyles={customHeadStyles}
            enableStickyHeader={true}
            positionPagination='top'
            arrayHeadersColor={[
              'yieldHistory',
              'yieldEstimatePrePoda',
              'yieldObjective',
              'yieldEstimatePostPoda',
              'yieldEstimateCounterFruitsPreRaleo',
              'yieldEstimateCounterFruitsPostRaleo',
              'yieldReal',
            ]}
            memoryColumns={library_table_yieldcalculator}
            tableid='library_table_yieldcalculator'
          />
        </div>
        <Modal
          open={openModalState}
          onClose={handleCloseModal}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalDetail
              closeAction={handleCloseModal}
              dataModal={dataModalState}
              dataColumn={columns}
            />
          </>
        </Modal>
        <Modal
          open={openModalPrintState}
          onClose={handleCloseModalPrint}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalDetailPrint
              closeAction={handleCloseModalPrint}
              dataModal={dataModalPrintState}
            />
          </>
        </Modal>
        <Modal
          open={openModalEditState}
          onClose={handleCloseModalEdit}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalEdit
              closeAction={handleCloseModalEdit}
              dataModal={dataModalEditState}
              isEditedFunc={handleIsEdited}
            />
          </>
        </Modal>
      </BsCol>
    </BsRow>
  );
};

export default FirstTab;
